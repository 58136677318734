
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { IreAreaObject } from '@/store/modules/IRE/AreasModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Multiselect from '@vueform/multiselect';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ire-areas-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitSizeButton = ref<HTMLButtonElement | null>(null);
      const submitPropertyAgeButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasIreValuesUpdating'), [
          translate('ire'),
        ]);
      });

      await store.dispatch(Actions.GET_All_IRE_V2_SIZE);

      const sizeOptions = await store.getters.ireV2Size;

      const { data } = await store.dispatch(
        Actions.GET_IRE_V2_AREA,
        route.params.id
      );
      const area = ref<IreAreaObject>(data.data);
      if (area.value.customUpdatedAt) {
        const dateString = new Date(area.value.customUpdatedAt);

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        area.value.customUpdatedAt = `${year}-${month}-${day}`;
      } else {
        area.value.customUpdatedAt = '';
      }
      // let new = []
      if (area.value['ire_size_config_v2'].length < 3) {
        //  const difference  = area.value['ire_size_config_v2'].filter(x => !sizeOptions.map(y => y.id).includes(x.ire_size_v2.id));
        const difference = sizeOptions.filter(
          (x) =>
            !area.value['ire_size_config_v2']
              .map((y) => y.ire_size_v2.id)
              .includes(x.id)
        );

        const sizeOptionsValue = difference.reduce(
          (previousValue, currentValue) => {
            previousValue.push({
              ireSizeId: currentValue.id,
              isBase: false,
              value: null,
              percentage: null,
              isEnabled: false,
              ire_size_v2: currentValue,
            });

            return previousValue;
          },
          []
        );
        area.value['ire_size_config_v2'] =
          area.value['ire_size_config_v2'].concat(sizeOptionsValue);
      }

      if (area.value['ire_property_age_config_v2'].length == 0) {
        await store.dispatch(Actions.GET_All_IRE_V2_PROPERTY_AGE);

        const propertyAgeOptions = store.getters.ireV2PropertyAge.map((p) => ({
          irePropertyAgeId: p.id,
          ire_property_age_v2: { titleAr: p.title ? p.title : p.titleAr },
          titleAr: p.title ? p.title : p.titleAr,
          percentage: null,
        }));

        area.value['ire_property_age_config_v2'] = propertyAgeOptions;
      }

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const areaSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
      });
      const onSubmitUpdate = async (values) => {
        debugger;
        console.log(values);
        delete values.AreasName;
        values.price = Number(values.price);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          basePrice: Number(values.basePrice),
          electricity: Number(values.electricity),
          sort: Number(values.sort),
          areaId: area.value?.areaId,
          customUpdatedAt: values.customUpdatedAt
            ? values.customUpdatedAt
            : undefined,
        };
        await store.dispatch(Actions.UPDATE_IRE_V2_AREA, {
          id: area.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'irev2-areas-listing' });
          });
        }
      };
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'irev2' });

      const onSubmitSizeOptions = async () => {
        // const sizeOptions: any = [];

        if (
          area.value['ire_size_config_v2'].filter((obj) => obj.isBase === true)
            .length > 1
        ) {
          Swal.fire({
            text: translate('ONE_IRE_BASE_SIZE_OPTIONS'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else if (
          area.value['ire_size_config_v2'].every((obj) => obj.isBase != true)
        ) {
          Swal.fire({
            text: translate('MUST_ADDED_IRE_BASE_SIZE_OPTIONS'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          const sizeOptionsValue = area.value['ire_size_config_v2'].reduce(
            (previousValue, currentValue) => {
              if (currentValue.value || currentValue.percentage) {
                ///fix
                previousValue.push({
                  ireSizeId: currentValue.ire_size_v2.id,
                  isBase: currentValue.isBase ? true : false,
                  value: currentValue.value ? currentValue.value : null,
                  percentage: currentValue.percentage,
                });
              }
              return previousValue;
            },
            []
          );

          const payload = {
            ireAreaId: area.value?.id,
            sizeOptionsValue,
          };

          if (submitSizeButton.value) {
            // eslint-disable-next-line
            submitSizeButton.value!.disabled = true;
            submitSizeButton.value.setAttribute('data-kt-indicator', 'on');
          }

          await store.dispatch(
            Actions.UPDATE_IRE_SIZE_CONFIG_OPTIONS_V2,
            payload
          );
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
            //Deactivate indicator
            submitSizeButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitSizeButton.value!.disabled = false;
          } else {
            await store.dispatch(
              Actions.GET_All_IRE_V2_SIZE_DESC,
              area.value?.id
            );
            // sizeDescOptions = store.getters.ireV2SizeDesc;

            Swal.fire({
              text: translate('SUCCESSFULLY_ADDED_IRE_SIZE_OPTIONS'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              //Deactivate indicator
              submitSizeButton.value?.removeAttribute('data-kt-indicator');
              // eslint-disable-next-line
              submitSizeButton.value!.disabled = false;
              router.go(0);
            });
          }
        }
      };

      // const propertyAgeOptions = area.value['ire_property_age_config_v2'].map((p) => ({
      //         id: p.id,
      //         ireAreaId: p.ireAreaId,
      //         irePropertyAgeId: p.irePropertyAgeId,
      //         percentage: p.percentage,

      // }));

      const onSubmitAgeOptions = async () => {
        // const propertyAgeOptions: any = [];
        const propertyAgeOptionsValue = area.value[
          'ire_property_age_config_v2'
        ].reduce((previousValue, currentValue) => {
          previousValue.push({
            irePropertyAgeId: currentValue.irePropertyAgeId,
            percentage: currentValue.percentage,
          });
          return previousValue;
        }, []);

        const payload = {
          ireAreaId: area.value?.id,
          propertyAgeOptionsValue,
        };

        if (submitPropertyAgeButton.value) {
          // eslint-disable-next-line
          submitPropertyAgeButton.value!.disabled = true;
          submitPropertyAgeButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(
          Actions.UPDATE_IRE_PROPERTY_AGE_CONFIG_OPTIONS,
          payload
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitPropertyAgeButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitPropertyAgeButton.value!.disabled = false;
        } else {
          // await store.dispatch(
          //   Actions.GET_All_IRE_V2_SIZE_DESC,
          //   createdArea.value.id
          // );
          // sizeDescOptions = store.getters.ireV2SizeDesc;

          Swal.fire({
            text: translate('SUCCESSFULLY_ADDED_IRE_PROPERTY_AGE_OPTIONS'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitPropertyAgeButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitPropertyAgeButton.value!.disabled = false;

            if (area.value['ire_property_age_config_v2'].length == 0) {
              router.go(0);
            }
          });
        }
      };

      const removeSizeConfig = async (id) => {
        const deletedMessage = 'confirmInActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          // loading.value = true;
          await store.dispatch(Actions.DELETE_IRE_V2_SIZE_CONFIG, id);

          // await store.dispatch(Actions.GET_IRE_V2_AREAS);
          // loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = 'SUCCESSFULLY_UPDATED_IRE_SIZE';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(() => {
              //Deactivate indicator
              submitPropertyAgeButton.value?.removeAttribute(
                'data-kt-indicator'
              );
              // eslint-disable-next-line
              submitPropertyAgeButton.value!.disabled = false;
              router.go(0);
            });
          }
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        sizeOptions,
        onSubmitAgeOptions,
        submitPropertyAgeButton,
        onSubmitSizeOptions,
        submitSizeButton,
        onSubmitUpdate,
        areas: computed(() => store.getters.allListAreas),
        removeSizeConfig,
        submitButton,
        translate,
        areaSchema,
        goBack,
        area,
        can,
      };
    },
  });
